<!-- <template>
  <div class="jobhunting">
    <div class="job-search">
      <div class="search-content">
        <div class="search">
          <el-form ref="form" :model="form">
            <el-form-item>
              <div class="search-input">
                <el-input
                  v-model="form.searchKey"
                  placeholder="请输入内容"
                ></el-input>

                <el-select
                  v-model="form.field2"
                  clearable
                  placeholder="公司行业"
                >
                  <el-option
                    v-for="item in educationlist"
                    :key="item.cCode"
                    :label="item.cName"
                    :value="item.cCode"
                  ></el-option>
                </el-select>
              </div>
              <div class="search-btn">
                <el-button
                  type="primary"
                  v-preventReClick
                  @click="
                    form.pageNumber = 1;
                    GetList();
                  "
                  >搜索</el-button
                >
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="search-hottel">
          <img src="../../assets/img/hottel.png" alt="" />客服热线：<span
            >0579-89302257</span
          >
        </div>
      </div>
    </div>
    <div class="job-position">
      <div class="position-content">
        <div class="position-tab-title">
          <div
            @click="
              cur = 0;
              form.pageNumber = 1;
              GetList();
            "
            style="cursor: pointer"
            :class="{ active: cur == 0 }"
          >
            推荐岗位
          </div>
          <div
            @click="
              cur = 1;
              form.pageNumber = 1;
              GetList();
            "
            style="cursor: pointer"
            :class="{ active: cur == 1 }"
          >
            最新发布
          </div>
        </div>
        <div class="position-tab">
          <div class="position-tab-content">
            <div>
              <div
                class="position-item"
                v-for="(item, i) in postdata"
                :key="i"
                @mouseenter="enter(i)"
                @mouseleave="leave()"
                @click="goto('/jobdetail', { id: item.oF_ID })"
              >
                <div class="item-list">
                  <div class="post-name">
                    {{ item.oF_POSI_NAME
                    }}<span>{{ item.oF_POSI_NATURE }}</span>
                  </div>
                  <div class="post-data">
                    {{
                      item.oF_UPDATE_DATE
                        ? stringToDates(item.oF_UPDATE_DATE) + "发布"
                        : ""
                    }}
                  </div>
                  <div class="post-conpany">{{ item.com_name }}</div>
                </div>

                <div class="item-list">
                  <div class="post-salary">
                    {{
                      item.oF_SALARY_MY == 1
                        ? "面议"
                        : setsalary(item.oF_SALARY, item.oF_SALARYE)
                    }}
                  </div>

                  <div class="post-detail">
                    <div>{{ item.oF_WORKAREAName }}</div>
                    <div v-if="item.oF_GZJY">|</div>
                    <div>
                      <span
                        >{{ item.oF_GZJY ? item.oF_GZJY + "经验" : "" }}
                      </span>
                    </div>
                    <div v-if="item.oF_EDU_REQ">|</div>
                    <div class="desc">
                      {{
                        item.oF_EDU_REQ
                          ? setOFEDUREQ(item.oF_EDU_REQ) + "学历"
                          : ""
                      }}
                    </div>
                    <div v-if="item.oF_INVITE_NUM">|</div>
                    <div>
                      <span>{{ item.oF_INVITE_NUM }} 人</span>
                    </div>
                  </div>

                </div>
                <div class="item-list">
                  <div
                    class="post-welfare"
                    v-for="(fitem, findex) in setflzl(item.oF_FL)"
                    :key="findex"
                  >
                    <span>{{ fitem }}</span>
                  </div>

                  <div class="deliver-btn" v-show="curr == i">
                    <el-button type="primary">职位申请</el-button>
                  </div>
                </div>
              </div>
            </div>
     
            <div style="text-align: center; margin-bottom: 20px">
              <el-pagination
                hide-on-single-page
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="form.pageNumber"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
    
        </div>
        <el-empty
          style="background: #fff"
          v-if="postdata.length < 1"
          :image-size="200"
        ></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Getindexsearch,
  Addresume,
  Getposilist,
  Gethotcompanylist,
} from "@/api/home";
import { isnull } from "@/assets/js/index";
import utils from "@/assets/js/utils";
import global from "@/assets/js/globalconfig";
export default {
  data() {
    return {
      defimgurl: global.baseimgurl,
      form: {
        searchKey: "",
        region: "",
        industry: "",
        field: "",
        field1: "", //职位类型
        field2: "", //公司行业
        field3: "", //工作经验
        field4: "", //学历要求
        field5: "", //薪资要求
        field6: "", //融资阶段
        field7: "", //公司规模
        order: "", //排序
        pageNumber: 1,
        pageSize: 10,
      },
      total: 0,
      cur: 0,
      postdata: [],
      curr: 0,
      companydata: [
        {
          id: 1,
          name: "李宁",
          logo: require("@/assets/img/logo-ln.png"),
          postnum: 3,
        },
        {
          id: 1,
          name: "华为",
          logo: require("@/assets/img/logo-ln.png"),
          postnum: 3,
        },
        {
          id: 1,
          name: "小米",
          logo: require("@/assets/img/logo-ln.png"),
          postnum: 3,
        },
        {
          id: 1,
          name: "鸿星尔克",
          logo: require("@/assets/img/logo-ln.png"),
          postnum: 3,
        },
      ],
      industrylist: [], //行业
      educationlist: [], //学历
      scalelist: [], //规模
      user: {},
    };
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  created() {
    this.form.searchKey = this.$route.query.key;
    this.GetList();
    this.Getposilists(1, "学历");
    this.Getposilists(2, "行业");
    this.Getposilists(3, "公司规模");
    // this.Gethotcompanylists();
    if (!isnull(localStorage.getItem("userinfo"))) {
      this.user = JSON.parse(localStorage.getItem("userinfo"));
      return false;
    }
  },
  methods: {
    stringToDates(data) {
      return utils.stringToDate(data);
    },
    enter(i) {
      this.curr = i;
    },
    leave() {
      this.curr = null;
    },
    goPage(path) {
      this.$router.push(path);
    },
    GetList() {
      var _this = this;
      _this.form.order = _this.cur === 0 ? "" : "desc";
      _this.form.showLoadType = ".position-tab-content";
      Getindexsearch(_this.form).then((res) => {
        if (res.success) {
          _this.postdata = res.data.rows;
          _this.total = res.data.total;
          _this.backtop();
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    handleSizeChange(val) {
      this.form.pageNumber = 1;
      this.form.pageSize = val;
      this.GetList();
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val;
      this.GetList();
    },
    setsalary(a, b) {
      var data = [];
      if (!isnull(a)) {
        if (a < 100 && a > 0) {
          data.push(a + "k");
        } else if (a >= 1000) {
          data.push(a / 1000 + "k");
        } else {
          data.push(a);
        }
      }
      if (!isnull(b)) {
        if (b < 100 && b > 0) {
          data.push(b + "k");
        } else if (b >= 1000) {
          data.push(b / 1000 + "k");
        } else {
          data.push(b);
        }
      }
      if (data.length === 0) {
        return "面议";
      } else {
        return data.join("-");
      }
    },
    setflzl(item) {
      var data = item.split(",");
      data.forEach((element, i) => {
        if (!element) {
          data.splice(i, 1);
        }
      });
      if (data.length > 8) {
        data = data.splice(0, 8);
      }

      return data;
    },
    backtop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //简历投递
    Addresumes(id) {
      var _this = this;
      var userinfo = { peR_ID: "" };
      if (!isnull(localStorage.getItem("userinfo"))) {
        userinfo = JSON.parse(localStorage.getItem("userinfo"));
      }

      var par = {
        id: id,
        uid: userinfo.peR_ID,
        showLoadType: ".position-tab-content",
      };
      Addresume(par).then((res) => {
        if (res.success) {
          _this.$message({
            message: "简历投递成功",
            type: "success",
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    goto(url, data) {
      this.resolvewin(url, data, 2);
    },
    Getposilists(type, datas) {
      var _this = this;
      Getposilist({ types: datas }).then((res) => {
        if (res.success) {
          switch (type) {
            case 1: //行业
              _this.industrylist = res.data.rows;
              break;
            case 2: //学历
              _this.educationlist = res.data.rows;
              break;
            case 3: //规模
              _this.scalelist = res.data.rows;
              break;
            default:
              break;
          }
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    setOFEDUREQ(data) {
      return isnull(data) ? "不限" : data;
    },
    clearsel() {
      this.form = {
        searchKey: "",
        region: "",
        industry: "",
        field: "",
        field1: "", //职位类型
        field2: "", //公司行业
        field3: "", //工作经验
        field4: "", //学历要求
        field5: "", //薪资要求
        field6: "", //融资阶段
        field7: "", //公司规模
        order: "", //排序
        pageNumber: 1,
        pageSize: 10,
      };
      this.GetList();
    },
    Gethotcompanylists() {
      var _this = this;
      Gethotcompanylist().then((res) => {
        if (res.success) {
          _this.companydata = res.data.rows.filter((res, index) => {
            return index < 6;
          });
        
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    setcompanylogo(url) {
      return isnull(url)
        ? require("@/assets/img/deflogo.png")
        : this.defimgurl + url;
    },
  },
};
</script>

<style lang="scss" scoped>
.job-search {
  position: relative;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
  margin-bottom: 16px;
  .search-content {
    width: 1200px;
    margin: 0px auto;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    .search {
      flex: 1;
      width: 100%;
      .el-form {
        .el-form-item ::v-deep {
          margin-bottom: 0;
          .el-form-item__content {
            display: flex;
            align-items: stretch;
            .search-input {
              display: flex;
              border: 1px solid #eee;
              .el-input {
                .el-input__inner {
                  border: none;
                  width: 400px;
                }
              }
              .el-select {
                .el-input__inner {
                  border: none;
                  width: 300px;
                }
                .el-input::before {
                  content: "";
                  position: absolute;
                  width: 1px;
                  height: 18px;
                  left: 0px;
                  top: 11px;
                  background: #ddd;
                }
              }
            }
            .search-btn {
              .el-button {
                background: #66b1ff;
                border-radius: 0;
                height: 100%;
                padding: 12px 30px;
                width: 150px;
              }
            }
          }
        }
      }
    }
    .search-hottel {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }
      span {
        font-size: 18px;
        color: #66b1ff;
      }
    }
  }
  .select-content {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .select-cell {
      .el-form {
        .el-form-item ::v-deep {
          margin-bottom: 0;
          .select-input {
            .el-select {
              margin-right: 60px;
              .el-input__inner {
                width: 110px;
                padding-left: 0;
                border: none;
              }
            }
          }
        }
      }
    }
    .select-clear {
      font-size: 14px;
      color: #787878;
      line-height: 40px;
      cursor: pointer;
    }
  }
}
.job-position {
  position: relative;
  width: 1200px;
  margin: 0px auto;
  .position-content {
    .position-tab-title {
      width: 100%;
      line-height: 44px;
      background: #fff;
      height: 44px;
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      div {
        padding: 0px;
        margin: 0px 24px;
        font-size: 14px;
        position: relative;
      }
      .active {
        color: #66b1ff;
      }
      .active::after {
        position: absolute;
        content: "";
        height: 3px;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #66b1ff;
        border-radius: 2px;
      }
    }
    .position-tab {
      position: relative;
      display: flex;
      align-items: stretch;
      .position-tab-content {
        flex: 1;
        width: 100%;
        div {
          .position-item {
            cursor: pointer;
            background: #fff;
            padding: 16px 24px;
            transition: all 0.5s;
            margin-bottom: 12px;
            .post-conpany {
              flex: 1;
              width: 100%;
              font-size: 16px;
              text-align: right;
              color: #666;
            }
            .item-list {
              display: flex;
              align-items: center;
              margin-bottom: 16px;
              position: relative;

              .post-num {
                flex: 1;
                width: 100%;
                text-align: right;
              }
              .deliver-btn {
                flex: 1;
                width: 100%;
                text-align: right;
                transition: all 1s;
                position: absolute;
                right: 0;
                z-index: 10;
                .el-button {
                  padding: 8px 20px;
                }
              }
              .post-name {
                font-size: 16px;
                color: #000;
                font-weight: bold;
                margin-right: 16px;
                span {
                  color: #67c23a;
                  margin: 0 15px;
                  font-weight: normal;
                }
              }
              .post-data {
                font-size: 14px;
                color: #787878;
              }
              .post-salary {
                font-size: 16px;
                color: rgba(255, 118, 48, 1);
                margin-right: 16px;
              }
              .post-detail {
                font-size: 14px;
                color: #787878;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                justify-content: space-between;

                span {
                  padding: 0px 8px;
                }
                .descinfo {
                  max-width: 500px;
                  margin: 10px;
                }
                div {
                  margin-right: 10px;
                }
              }
              .post-num {
                font-size: 14px;
                color: #66b1ff;
              }
              .post-welfare {
                span {
                  padding: 5px 8px;
                  font-size: 12px;
                  color: #fff;
                  background: #3098ff;
                  border-radius: 2px;
                  margin-right: 12px;
                }
              }
            }
            .item-list:last-child {
              margin-bottom: 6px;
            }
          }
        }
      }
      .position-other {
        width: 310px;
        margin-left: 12px;
        .edit-resume {
          padding: 16px 24px;
          background: #fff;
          margin-bottom: 12px;
          a {
            color: #66b1ff;
            padding: 12px;
            background: #fff;
            border: 1px solid #66b1ff;
            display: block;
            text-decoration: none;
          }
        }
        .other-ad {
          margin-bottom: 12px;
          display: flex;
          img {
            width: 100%;
          }
        }
        .other-copmpany {
          background: #fff;
          padding: 16px;
          .other-company-title {
            font-size: 18px;
            color: #333;
            padding-bottom: 16px;
            margin-bottom: 16px;
            text-align: left;
            border-bottom: 1px solid #eee;
          }
          .other-company-cell {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .company-item {
              width: 275px;
              padding: 12px 0;
              background: #f2f2f2;
              margin-bottom: 16px;
              position: relative;
              overflow: hidden;
              cursor: pointer;
              .company-logo {
                height: 50px;
                text-align: center;
                img {
                  height: 50px;
                }
              }
              .company-name {
                font-size: 14px;
                line-height: 32px;
                color: #333;
                text-align: center;
                width: 70%;
                margin: 0 auto;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
              .company-num {
                padding: 8px 0px;
                border-radius: 2px;
                border: 1px solid #eee;
                font-size: 12px;
                background: #fff;
                width: 180px;
                margin: 0px auto;
                text-align: center;
                b {
                  color: #ff0000;
                }
              }
            }
            .company-item:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
</style> -->

<template>
  <div class="jobhunting">



    <!-- <div class="job-search">
            <div class="search-content">
                <div class="search">
                    <el-form ref="form" :model="form">
                        <el-form-item>
                            <div class="search-input">
                                <el-input v-model="form.searchKey" placeholder="请输入关键字"></el-input>
                            </div>
                            <div class="search-btn">
                                <el-button type="primary" v-preventReClick @click="search()">搜索</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="search-hottel">
                    <img src="../../assets/img/hottel.png" alt="" />客服热线：<span>0579-89302257</span>
                </div>
            </div>
        </div> -->


    <Header style="display:inline-block;"></Header>

    <div class="job-position">
      <div class="position-content">
        <div class="position-tab-title">
          <div class="position-tab-a" v-for="(Sitem, Sindex) in searcList" :key="Sindex">
            <div class="title">{{ Sitem.name }}：</div>
            <div class="info flex1">
              <div v-for="(Szitem, Szindex) in Sitem.data" :key="Szindex" @click="SelectTap(Sindex, Szindex)" :class="[
                'info-a',
                Sitem.clcik == Szitem.value ? 'info-Select' : '',
              ]">
                {{ Szitem.name }}
              </div>
            </div>
          </div>
          <!-- <div class="position-tab-a flex1">
                        <div class="title">年龄：</div>
                        <div class="info flex1" v-if="!isfieldchage">
                            <div class="info-a info-Select">不限</div>
                            <div class="info-a" @click="isfieldchage = true">选取区间</div>
                        </div>
                        <div class="info flex1" v-if="isfieldchage">
                            <el-input-number v-model="field1" :min="18" :max="field2"></el-input-number>
                            <span>至</span>
                            <el-input-number v-model="field2" :min="field1" :max="80"></el-input-number>
                            <el-button style="margin-left: 40px" @click="yearselect()" type="primary">确认</el-button>
                            <el-button style="margin-left: 20px" @click="
                                (stateyear = ''),
                                (endyear = ''),
                                (isfieldchage = false),
                                GetList()
                            " type="info">不限</el-button>
                        </div>
                    </div> -->
        </div>

        <div class="center">


          <div class="position-tab">

            <div class="sort_order">
              <div class="position-tab-a" v-for="(Sitem, Sindex) in sortList" :key="Sindex">
                <div class="title">{{ Sitem.name }}：</div>
                <div class="info flex1">
                  <div v-for="(Szitem, Szindex) in Sitem.data" :key="Szindex" @click="SelectTap(Sindex, Szindex)"
                    :class="[
                      'info-a',
                      Sitem.clcik == Szitem.value ? 'info-Select' : '',
                    ]">
                    {{ Szitem.name }}
                  </div>
                </div>
              </div>

              <div class="position-tab-a1">
                <div class="title">薪资待遇：</div>
                <el-dropdown :hide-on-click="false">
                  <span class="el-dropdown-link">
                    不限<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>10-18k</el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="position-tab-a1">
                <div class="title">工作性质：</div>
                <el-dropdown :hide-on-click="false">
                  <span class="el-dropdown-link">
                    不限<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item>10-18k</el-dropdown-item>

                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
            <div class="position-tab-content">
              <div class="resumelist">
                <div class="resume" v-for="(resumeItem, resume) in PerJobList" :key="resume"
                 @click="goto('/jobdetail', { id: 35346 })"
                >
                  <div class="recruitment">
                    <div classs="recruitment_left">
                      <div style="font-size: 16px;color: rgba(16, 16, 16, 100);"> <span>IOS开发工程师</span> <span
                          style="color: rgba(246, 142, 8, 100);margin-left:26px">12K-18K</span></div>
                      <div style="color: rgba(137, 137, 137, 100);font-size: 14px;margin-top:10px;">
                        <span style="margin-right: 10px;">1-3年</span>|<span style="margin-left: 10px;">本科</span>
                      </div>
                    </div>
                    <div classs="recruitment_right" style="display:flex;">
                      <div
                        style="width: 50px;height: 50px;border:1px solid #cacaca;border-radius: 25px;margin-right: 23px;">
                        <img style="width: 50px;
                                height: 50px;
                                border-radius: 25px;" src="../../assets/img/qytx.png" />
                      </div>
                      <div>
                        <div style="font-size: 16px;color: #666666;"> <span>金华六和机械有限公司</span>
                        </div>

                        <div style="color: rgba(137, 137, 137, 100);font-size: 14px;margin-top:10px;">
                          <span style="margin-right: 10px;">其他</span>|<span
                            style="margin-left: 10px;margin-right: 10px;">私企</span>|<span
                            style="margin-left: 10px;">临海</span>
                        </div>

                      </div>


                    </div>
                  </div>
                  <div class="treatment">
                    <div class="tag">
                      <el-tag color="#E6F0FF" style="margin-right:14px;height: 24px;height: 24px;color: rgba(137, 137, 137, 100);line-height: 24px;">五险一金</el-tag>
                      <el-tag color="#E6F0FF" style="margin-right:14px;height: 24px;height: 24px;color: rgba(137, 137, 137, 100);line-height: 24px;">晋升空间</el-tag>
                      <el-tag color="#E6F0FF" style="margin-right:14px;height: 24px;height: 24px;color: rgba(137, 137, 137, 100);line-height: 24px;">带薪年假</el-tag>
                      <el-tag color="#E6F0FF" style="margin-right:14px;height: 24px;height: 24px;color: rgba(137, 137, 137, 100);line-height: 24px;">住房补贴</el-tag>
                    </div>

                    <div style="color: rgba(137, 137, 137, 100);font-size: 14px;margin: auto 0; padding-right: 19px;">
                      <span style="margin-right: 10px;">其他</span>|<span style="margin-left: 10px;">私企</span>
                      <span>12:30发布</span>
                    </div>
                  </div>
                </div>
              </div>



              <div style="text-align: center;margin-bottom: 20px">
                <el-pagination hide-on-single-page @size-change="handleSizeChange" @current-change="handleCurrentChange"
                  :current-page="form.pageNumber" :page-sizes="[10, 20, 30, 40]" :page-size="form.pageSize"
                  layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </div>

            <el-empty style="background: #fff" v-if="PerJobList.length < 1" :image-size="200"></el-empty>
          </div>
          <div class="recent">
            <div class="recentBrowsing">
              <div class="title">
                <span>最近浏览</span>
              </div>
              <div class="occupation">
                <div class="occupation_company">
                  <div>IOS开发工程师</div>
                  <div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
                </div>
                <div class="salary">
                  <span>12K-18K</span>
                </div>
              </div>

              <div class="occupation">
                <div class="occupation_company">
                  <div>数据分析师</div>
                  <div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
                </div>
                <div class="salary">
                  <span>12K-18K</span>
                </div>
              </div>

              <div class="occupation">
                <div class="occupation_company">
                  <div>web前端工程师</div>
                  <div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
                </div>
                <div class="salary">
                  <span>12K-18K</span>
                </div>
              </div>

              <div class="occupation">
                <div class="occupation_company">
                  <div>视觉设计师</div>
                  <div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
                </div>
                <div class="salary">
                  <span>12K-18K</span>
                </div>
              </div>

              <div class="occupation">
                <div class="occupation_company">
                  <div>UI设计师</div>
                  <div style="color: rgba(137, 137, 137, 100);margin-top:15px;">金华六和机械有限公司</div>
                </div>
                <div class="salary">
                  <span>12K-18K</span>
                </div>
              </div>
            </div>

            <div class="advertisement">
              <!-- <img src="" alt="" /> -->
            </div>

            <div class="advertisement">
              <!-- <img src="" alt="" /> -->
            </div>

          </div>



        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import {
  Getindexsearch,
  Addresume,
  Getposilist,
  Gethotcompanylist,
} from "@/api/home";
import {
  favuser,
  cancelfavuser,
  searchperson,
  ispaybycomid,
} from "@/api/company";
import {
  isnull,
  handleCofirm,
} from "@/assets/js/index";
import utils from "@/assets/js/utils";
import global from "@/assets/js/globalconfig";
import Header from "@/components/header1.vue";
import Footer from "@/components/footer1.vue";
export default {
  components: {
    Header,
    Footer
  },

  data() {
    return {
      defimgurl: global.baseimgurl,
      form: {
        searchKey: "",
        pageNumber: 1,
        pageSize: 7,
      },
      total: 0,
      cur: 0,
      PerJobList: [],
      curr: 0,

      educationlist: [], //学历
      isfieldchage: false,
      field1: 18,
      stateyear: "",
      field2: 80,
      endyear: "",
      user: {},
      sortList: [

        {
          clcik: "",
          name: "排序方式",
          data: [{
            name: "默认",
            value: ""
          },
          {
            name: "职位数量",
            value: "职位数量"
          },

          ],
        },
      ],
      searcList: [
        {
          clcik: "",
          name: "工作地点",
          data: [{
            name: "全部",
            value: ""
          },
          {
            name: "云山街道",
            value: "云山街道"
          },
          {
            name: "兰江街道",
            value: "兰江街道"
          },
          {
            name: "上华街道",
            value: "上华街道"
          },
          {
            name: "永昌街道",
            value: "永昌街道"
          },
          {
            name: "赤溪街道",
            value: "赤溪街道"
          },
          {
            name: "女埠街道",
            value: "女埠街道"
          },
          {
            name: "游埠镇",
            value: "游埠镇"
          },
          {
            name: "诸葛镇",
            value: "诸葛镇"
          },
          {
            name: "马涧镇",
            value: "马涧镇"
          },
          {
            name: "香溪镇",
            value: "香溪镇"
          },
          {
            name: "黄店镇",
            value: "黄店镇"
          },
          {
            name: "梅江镇",
            value: "梅江镇"
          },
          {
            name: "横溪镇",
            value: "横溪镇"
          },
          {
            name: "柏社乡",
            value: "柏社乡"
          },
          {
            name: "灵洞乡",
            value: "灵洞乡"
          },
          {
            name: "水亭畲族乡",
            value: "水亭畲族乡"
          },
          ],
        },
        {

          name: "工作经验",
          data: [{
            name: "全部",
            value: ""
          }, {
            name: "应届毕业生",
            value: "应届毕业生"
          }, {
            name: "1-3年",
            value: "1-3年"
          }
            , {
            name: " 3年  ",
            value: " 3年  "
          }, {
            name: " 3-5年 ",
            value: " 3-5年"
          }, {
            name: "5-10年",
            value: "5-10年"
          }, {
            name: "10年以上",
            value: "10年以上"
          }


          ],
        },
        {

          name: "学历要求",
          data: [{
            name: "全部",
            value: ""
          }, {
            name: "应届毕业生",
            value: "应届毕业生"
          }, {
            name: "1-3年",
            value: "1-3年"
          }
            , {
            name: " 3年  ",
            value: " 3年  "
          }, {
            name: " 3-5年 ",
            value: " 3-5年"
          }, {
            name: "5-10年",
            value: "5-10年"
          }, {
            name: "10年以上",
            value: "10年以上"
          }


          ],
        },


        {
          clcik: "",
          name: "公司性质",
          data: [{
            name: "全部",
            value: ""
          },
          {
            name: "私企",
            value: 1
          },
          {
            name: "国企",
            value: 2
          },
          {
            name: " 个体户",
            value: 3
          },
          {
            name: "上市",
            value: 4
          },
          {
            name: "事业单位",
            value: 4
          },

          ],
        },
        {

          clcik: "",
          name: "公司规模",
          data: [{
            name: "全部",
            value: ""
          },
          {
            name: "少于15人",
            value: 1
          },
          {
            name: "15-50人",
            value: 2
          },
          {
            name: " 50-100人",
            value: 3
          },
          {
            name: "100-200人",
            value: 4
          },
          { name: " 200-500人", value: 5 },
          { name: " 500人以上", value: 6 },
          ],
        },
        {

          name: "行业领域",
          data: [{
            name: "全部",
            value: ""
          }, {
            name: "电子商务",
            value: "电子商务"
          }
            , {
            name: "互联网",
            value: "互联网"
          }, {
            name: "金融",
            value: "金融"
          }, {
            name: "教育",
            value: "教育"
          }, {
            name: "旅游",
            value: "旅游"
          }, {
            name: "医疗",
            value: "医疗"
          }


          ],
        },
      ],
      idcompanylogin: false,
      ispaybycomiddata: 0,
    };
  },
  watch: {
    field1(value, o) {
      if (value < 18) {
        this.field1 = 18;
      } else if (value > 80) {
        this.field1 = 80;
      } else if (value > this.field2) {
        this.field1 = this.field2;
      } else {
        this.field1 = value;
      }
    },
    field2(value, o) {
      if (value < 18) {
        this.field2 = 18;
      } else if (value > 80) {
        this.field2 = 80;
      } else if (value < this.field1) {
        this.field2 = this.field1;
      } else {
        this.field2 = value;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.showbtn, true);
  },
  created() {
    if (localStorage.getItem("userinfo")) {
      this.user = JSON.parse(localStorage.getItem("userinfo"));
      if (this.user.coM_ID) {
        this.idcompanylogin = true;
        this.ispaybycomid();
      }
    }

    this.GetList();
    this.Getposilists();
  },
  methods: {
     goto(url, data) {
      this.resolvewin(url, data, 2);
    },
    ispaybycomid() {
      var _this = this;
      ispaybycomid({
        id: this.user.coM_ID
      }).then((res) => {
        _this.ispaybycomiddata = res.data;
      });
    },
    stringToDates(data) {
      return utils.stringToDate(data);
    },
    enter(i) {
      this.curr = i;
    },
    leave() {
      this.curr = null;
    },
    goPage(path) {
      this.$router.push(path);
    },
    SelectTap(i, j) {
      this.searcList[i].clcik = this.searcList[i].data[j].value;
      this.form.pageNumber = 1;
      this.GetList();
    },
    search() {
      this.form.pageNumber = 1;
      this.GetList();
    },
    yearselect() {
      var time = new Date();
      let year = time.getFullYear();
      this.stateyear = year - this.field1;

      this.endyear = year - this.field2;
      this.form.pageNumber = 1;
      this.GetList();
    },
    //人才简历列表
    GetList() {
      var _this = this;
      var par = {
        PageSize: _this.form.pageSize,
        PageNumber: _this.form.pageNumber,
        id: this.user.coM_ID,
        code: this.searcList[2].clcik,
        gender: this.searcList[0].clcik,
        edu: this.searcList[1].clcik,
        searchKey: _this.form.searchKey,
        field2: this.stateyear,
        field1: this.endyear,
        showLoadType: ".position-tab-content",
      };
      searchperson(par).then((res) => {
        if (res.success) {
          res.data.rows.forEach((element) => {
            element.hy = this.utils.handleDateHY(element.peR_LASTLOAD);
          });
          _this.total = res.data.total;
          _this.PerJobList = res.data.rows;
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    //人才收藏
    favuser(id) {
      var _this = this;
      var par = {
        id: this.user.coM_ID,
        uid: id,
      };
      favuser(par).then((res) => {
        if (res.success) {
          this.GetList();

          _this.$message.success("简历已收藏!");
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    //取消人才收藏
    cancelfavuser(id) {
      var _this = this;
      handleCofirm('确认取消收藏该简历吗？', 'warning',).then(res => {
        var par = {
          id: this.user.coM_ID,
          uid: id,
        };
        cancelfavuser(par).then((res) => {
          if (res.success) {
            this.GetList();

            _this.$message.success("已取消收藏!");
          } else {
            _this.$message.error(res.msg);
          }
        });
      }).catch(err => {
        //console.log('err', err) // cancel
      })
    },
    handleSizeChange(val) {
      this.form.pageNumber = 1;
      this.form.pageSize = val;
      this.GetList();
      this.backtop();
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val;
      this.GetList();
      this.backtop();
    },
    setsalary(a, b) {
      return utils.setsalary(a,b);

    },

    backtop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isTop = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },

    talk(url, data, type = 2) {
      this.resolvewin(url, data, type);
    },

    Getposilists() {
      var _this = this;
      Getposilist({
        types: "行业"
      }).then((res) => {
        if (res.success) {
          _this.educationlist = res.data.rows;
          res.data.rows.forEach((element) => {
            if (element.cName != "不限") {
              // this.searcList[1].data.push({
              //   name: element.cName,
              //   value: element.cCode,
              // });
            }
          });
        } else {
          _this.$message.error(res.msg);
        }
      });
    },
    setOFEDUREQ(data) {
      return isnull(data) ? "不限" : data;
    },
    clearsel() {
      this.form = {
        searchKey: "",
        pageNumber: 1,
        pageSize: 10,
      };
      this.GetList();
    },

    salary(data) {
      if (data.indexOf("-") == -1) {
        return "面议";
      } else {
        return data;
      }
    },

    setuserlogo(url) {
      var data = url.indexOf("http");

      return isnull(url) ?
        require("@/assets/img/logos.png") :
        data < 0 ?
          this.defimgurl + url :
          url;
    },
  },
};
</script>

<style lang="scss" scoped>
.job-search {
  position: relative;
  width: 100%;
  background: #fff;
  border-top: 1px solid #eee;
  margin-bottom: 16px;

  .search-content {
    width: 1200px;
    margin: 0px auto;
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;

    .search {
      flex: 1;
      width: 100%;

      .el-form {


        .el-form-item ::v-deep {
          margin-bottom: 0;

          .el-form-item__content {
            display: flex;
            align-items: stretch;

            .search-input {
              display: flex;
              border: 1px solid #e5e5e5;

              .el-input {
                .el-input__inner {
                  border: none;
                  width: 600px;
                }
              }
            }

            .search-btn {
              .el-button {
                background: #66b1ff;
                border-radius: 0;
                height: 100%;
                padding: 12px 30px;
                width: 150px;
              }
            }
          }
        }
      }
    }

    .search-hottel {
      display: flex;
      align-items: center;
      font-size: 14px;

      img {
        width: 22px;
        height: 22px;
        margin-right: 4px;
      }

      span {
        font-size: 18px;
        color: #66b1ff;
      }
    }
  }

  .select-content {
    width: 1200px;
    margin: 0px auto;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;

    .select-cell {
      .el-form {
        .el-form-item ::v-deep {
          margin-bottom: 0;

          .select-input {
            .el-select {
              margin-right: 60px;

              .el-input__inner {
                width: 110px;
                padding-left: 0;
                border: none;
              }
            }
          }
        }
      }
    }

    .select-clear {
      font-size: 14px;
      color: #787878;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

.job-position {
  position: relative;
  width: 1200px;
  margin: 0px auto;

  .position-content {
    .position-tab-title {
      background: #fff;
      margin-bottom: 20px;
      padding: 20px 20px 1px 20px;

      .position-tab-a {
        display: flex;
        font-size: 14px;
        margin-bottom: 20px;

        .title {
          font-weight: bold;
          color: #333;
          padding: 5px 10px;
          min-width: 90px;
        }

        .info {
          span {
            margin: 0 20px;
          }

          .info-a {
            cursor: pointer;
            padding: 5px 10px;
            margin-right: 10px;
          }

          .info-Select {
            color: #fff;
            background-color: #2778f8;
            border-radius: 4px;
          }
        }

        .el-dropdown-link {
          cursor: pointer;
        }
      }




    }

    .center {
      display: flex;

      .position-tab {
        position: relative;
        // display: flex;
        align-items: stretch;
        width: 70%;

        .sort_order {
          width: 100%;
          height: 28px;
          background: #fff;
          margin-bottom: 20px;
          display: flex;
          padding-top: 10px;
          padding-bottom: 10px;

          //   box-sizing: border-box;
          .position-tab-a {
            display: flex;
            font-size: 14px;
            margin-bottom: 20px;
            margin-left: 20px;

            .title {
              font-weight: bold;
              color: #333;
              padding: 5px 10px;
              min-width: 90px;
            }

            .info {
              span {
                margin: 0 20px;
              }

              .info-a {
                cursor: pointer;
                padding: 5px 10px;
                margin-right: 10px;
              }

              .info-Select {
                color: #fff;
                background-color: #2778f8;
                border-radius: 4px;
              }
            }

            .el-dropdown-link {
              cursor: pointer;
            }
          }

          .position-tab-a1 {
            display: flex;
            font-size: 14px;
            // margin-bottom: 20px;
            margin-left: 97px;
            margin-top: 5px;

            .title {
              font-weight: bold;
              color: #333;
              // padding: 5px 10px;
              // min-width: 90px;
            }


          }
        }

        .position-tab-content {

          width: 100%;
          background: #fff;
          padding-top: 10px;

          .resume {
            width: 95%;
            // padding: 20px;
            box-sizing: border-box;
            background: white;
            margin-bottom: 10px;
            cursor: pointer;
            margin-left: 2.5%;

            border: 1px solid #eee;

            .recruitment {
              display: flex;
              justify-content: space-between;
              padding: 15px 20px;

              .recruitment_right {
                display: flex;
              }


            }

            .treatment {
              width: 100%;
              height: 48px;
              background: #f8f8f8;
              display: flex;
              justify-content: space-between;

              .tag{
                padding-left: 13px;
                margin: auto 0;
              }
             
            }

          }

          .resume:hover {
            box-shadow: 0 0 6px rgba(39, 107, 242, 0.3);
          }
        }

        .position-other {
          width: 310px;
          margin-left: 12px;

          .edit-resume {
            padding: 16px 24px;
            background: #fff;
            margin-bottom: 12px;

            a {
              color: #66b1ff;
              padding: 12px;
              background: #fff;
              border: 1px solid #66b1ff;
              display: block;
              text-decoration: none;
            }
          }

          .other-ad {
            margin-bottom: 12px;
            display: flex;

            img {
              width: 100%;
            }
          }

          .other-copmpany {
            background: #fff;
            padding: 16px;

            .other-company-title {
              font-size: 18px;
              color: #333;
              padding-bottom: 16px;
              margin-bottom: 16px;
              text-align: left;
              border-bottom: 1px solid #eee;
            }

            .other-company-cell {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;

              .company-item {
                width: 275px;
                padding: 12px 0;
                background: #f2f2f2;
                margin-bottom: 16px;
                position: relative;
                overflow: hidden;
                cursor: pointer;

                .company-logo {
                  height: 50px;
                  text-align: center;

                  img {
                    height: 50px;
                  }
                }

                .company-name {
                  font-size: 14px;
                  line-height: 32px;
                  color: #333;
                  text-align: center;
                  width: 70%;
                  margin: 0 auto;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                .company-num {
                  padding: 8px 0px;
                  border-radius: 2px;
                  border: 1px solid #eee;
                  font-size: 12px;
                  background: #fff;
                  width: 180px;
                  margin: 0px auto;
                  text-align: center;

                  b {
                    color: #ff0000;
                  }
                }
              }

              .company-item:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }


      .recent {
        width: 35%;
        margin-left: 2%;

        .recentBrowsing {
          width: 100%;
          // height: 455px;
          background-color: #fff;

          .title {
            color: rgba(16, 16, 16, 100);
            font-size: 16px;
            width: 97%;
            height: 50px;
            border-bottom: 1px solid #eee;
            margin-left: 3%;
            line-height: 50px;

            span {
              margin-left: 14px;
            }
          }

          .occupation {
            color: rgba(16, 16, 16, 100);
            font-size: 14px;
            width: 94%;
            height: 100px;
            border-bottom: 1px solid #eee;
            margin-left: 3%;
            margin-right: 3%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .occupation_company {
              margin-left: 15px;
            }

            .salary {
              margin-right: 15px;
              color: #ff7630;
              font-size: 16px;

            }

          }
        }

        .advertisement {
          width: 100%;
          height: 252px;
          background: #0096FF;
          margin-top: 16px;
        }

      }
    }

  }
}
</style>
